<template>
  <router-view v-slot="{ Component}">
    <component :is="Component"   />
  </router-view>
</template>

<script setup>
import { computed } from "vue"
import { useRoute } from "vue-router"

const router = useRoute();
const currentRoute = computed( () => router.params.id);
</script>

<style lang="scss">
@import "the-new-css-reset/css/reset.css";
@import "~vue-multiselect/dist/vue-multiselect.css";
@import "styles/main";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>

