import { createRouter, createWebHistory } from "vue-router";
import store  from "../store/";

// const store = useStore();

const routes = [
    {
        path: "/",
        name: "Login",
        component: () => import("../views/LoginLayout"),
        children: [
            {
                path: "/",
                name: "SignIn",
                component: () => import(/* webpackChunkName: "LoginScreen" */ "../views/LoginViews/SignIn")
            },
            {
                path: "/forgotPassword",
                name: "ForgotPassword",
                component: () => import(/* webpackChunkName: "LoginScreen" */ "../views/LoginViews/ForgotPassword")
            },
            {
                path: "/renewPassword",
                name: "RenewPassword",
                component: () => import(/* webpackChunkName: "LoginScreen" */ "../views/LoginViews/RenewPassword")
            }
        ]
    },
    {
        path: "/app",
        component: () => import("../views/BaseLayout.vue"),
        children: [
            {
                path: '',
                name: "Search",
                component: () => import(/* webpackChunkName: "Search" */ "../views/AppViews/SearchPage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'merchants',
                name: "Merchants",
                component: () => import(/* webpackChunkName: "Merchants" */ "../views/AppViews/MerchantsPage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'merchants/:id',
                name: "MerchantSingle",
                component: () => import(/* webpackChunkName: "Merchant" */ "../views/AppViews/MerchantSinglePage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'stores',
                name: "Stores",
                component: () => import(/* webpackChunkName: "Stores" */ "../views/AppViews/StoresPage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'stores/:id',
                name: "StoreSingle",
                component: () => import(/* webpackChunkName: "Store" */ "../views/AppViews/StoreSinglePage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'managers',
                name: "Managers",
                component: () => import(/* webpackChunkName: "Managers" */ "../views/AppViews/ManagersPage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'managers/:id',
                name: "ManagerSingle",
                component: () => import(/* webpackChunkName: "Manager" */ "../views/AppViews/ManagerSingle"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'seller/:id',
                name: "SellerSingle",
                component: () => import(/* webpackChunkName: "Seller" */ "../views/AppViews/SellerSinglePage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'terminal/:id',
                name: "TerminalSingle",
                component: () => import(/* webpackChunkName: "Device" */ "../views/AppViews/TerminalsSinglePage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'admins',
                name: "Admins",
                component: () => import(/* webpackChunkName: "Admins" */ "../views/AppViews/AdminsPage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: false
                }
            },
            {
                path: 'admin/:id',
                name: "AdminSingle",
                component: () => import(/* webpackChunkName: "Admin" */ "../views/AppViews/AdminSingle"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: false
                }
            },
            {
                path: 'kernels',
                name: "Kernels",
                component: () => import(/* webpackChunkName: "Kernels" */ "../views/AppViews/KernelsPage"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: false
                }
            },
            {
                path: 'reports',
                name: "Reports",
                component: () => import(/* webpackChunkName: "Reports" */ "../views/AppViews/T"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: false
                }
            },
            {
                path: 'activity-log',
                name: "ActivityLog",
                component: () => import(/* webpackChunkName: "ActivityLogs" */ "../views/AppViews/T"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            },
            {
                path: 'permission-denied',
                name: "PermissionDenied",
                component: () => import(/* webpackChunkName: "PermissionDenied" */ "../views/AppViews/PermissionDenied"),
                meta:{
                    requiresAuth: true,
                    availabilityForManager: true
                }
            }
        ],

    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach((to, from, next) => {
//     if(to.matched.some(item => item.meta.requiresAuth)){
//         if(store.state.isLoggedIn){
//             console.log('123')
//             next();
//             return;
//         }
//
//         next({
//             name: "SignIn",
//             query: {
//                 nextUrl: to.fullPath,
//             },
//             replace: true
//         })
//
//     }
//     console.log(to);
//     next();
// })
//
// router.beforeEach((to, from, next) => {
//     if(to.matched.some(record => record.meta.requiresAuth)) {
//         console.log(store.getters.isLoggedIn)
//         if (store.getters.isLoggedIn) {
//             console.log('Logged in')
//             next();
//             return
//         } else {
//             console.log('in inner else - logged out')
//             next({
//                 name: "SignIn",
//                 query: { nextUrl: to.fullPath },
//                 replace: true
//             })
//             return
//         }
//     } else {
//         console.log('in outer else - out of')
//         next()
//     }
// })

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isLoggedIn;
    // console.log(store.getters.getCurrentUser)
    // const userRole = store.getters.getCurrentUser.role;
    // console.log(isAuthenticated, userRole)

    if (to.matched.some(record => record.meta.requiresAuth)) {
        const userRole = store.getters.getCurrentUser.role;
        if (!isAuthenticated) {
            // Save the intended route to query params
            next({
                name: 'SignIn',
                query: { nextUrl: to.fullPath }
            })
        } else if (userRole === 'administrator') {
            console.log('here 1')
            if (to.name === 'SignIn') {
                console.log('here 2')
                next({ name: 'Search' })
            } else {
                console.log('here 3')
                next()
            }
        } else {
            // Check if availabilityForManager is set to false
            if (to.matched.some(record => record.meta.availabilityForManager === false)) {
                next({ name: 'PermissionDenied' })
            } else {
                next()
            }
        }
    } else {
        // No authentication required
        next()
    }
})

export default router