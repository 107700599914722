import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Popper from "vue3-popper";
import Multiselect from "vue-multiselect";

const app = createApp(App);

app.component('MultiSelect', Multiselect)

const toastOptions = {
    transition: "Vue-Toastification__fade",
    maxToasts: 10,
    newestOnTop: true,
    hideProgressBar: true,
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    icon: false,
};
const popperOptions = {
    placement: "top"
}
// app.component("Popper", Popper);
app.use(store).use(router).use(Toast, toastOptions).use(Popper, popperOptions, popperOptions).mount('#app')