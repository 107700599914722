import { createStore } from 'vuex'

export default createStore({
  state: {
    currentUser: {},
    isLoggedIn: false,
    isAppReady: true,
    displayLogoutModal: false,
    displayAddMerchantModal: false,
    displayMerchantModalEDD: false,
    displayResetPassManagerModal: false,
    displayResetPassAdminModal: false,
    displayManagerModalAdd: false,
    displayManagerModalEdit: false,
    displayStoreModalEDD: false,
    displayStoreModalAdd: false,
    displayStoreModalEdit: false,
    displayManagerModalEDD: false,
    displayAdminModalEDD: false,
    displayAdminModalAdd: false,
    displayTerminalModalAdd: false,
    displayAdminModalEdit: false,
    displayCloseTimeModal: false,
    merchants: [
      {
        merchantName: 'Monster Legacy',
        merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_4',
        contactPhone: '+380639479191',
        categoryCode: '5412 - Grocery Stores, Supermarkets',
        taxNumber: '5469264574265964',
        bankID: '63275469264574265964',
        kernelProfile: 'Profile 1',
        managerEmail: 'n.hurley@flowers-shop.com',
        status: 'active',
        statusText: '',
        doc: '11:12, 20 March 2022',
        managersID: [],
        storesID: [],
        statusHistory: []
      },
      {
        merchantName: 'Steak House',
        merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_1',
        contactPhone: '+380972645164',
        categoryCode: '5419 - Grocery Stores, Supermarkets',
        taxNumber: '5469264574265964',
        bankID: '63275469264574265964',
        kernelProfile: 'Profile 3',
        managerEmail: 'n.hurley@flowers-shop.com',
        status: 'disabled',
        statusText: 'Status changed at 18:35, 14 April, 2022 by Briana Henson. Temporary account freeze.',
        doc: '11:12, 18 March 2022',
        managersID: ['qasw3ne3-xkuk-p6rj-lk15-2ma4uptutfw7', 'e3xyucn5-0a02-9khy-xhq4-kpqwfbmjek9y'],
        storesID: ['0441lh61-9lxl-l01i-80lr-059bl4il3478_2', '0441lh61-9lxl-l01i-80lr-059bl4il3478_4'],
        statusHistory: [
          {
            reason: 'Not a fraud',
            date: '12:02, 18 April, 2022',
            changedBy: 'Dr. Octopus',
            status: 'disabled'
          },
          {
            reason: 'Not a fraud',
            date: '13:42, 15 April, 2022',
            changedBy: 'Dr. Octopus',
            status: 'active'
          }
        ]
      },
      {
        merchantName: 'Fast taxi',
        merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_2',
        contactPhone: '+380948745767',
        categoryCode: '5410 - Grocery Stores, Supermarkets',
        taxNumber: '5469264574265964',
        bankID: '63275469264574265964',
        kernelProfile: 'Profile 1',
        managerEmail: 'n.hurley@flowers-shop.com',
        status: 'deactivated',
        statusText: '18:35, 14 April, 2022 by Jack Carson. The merchant closed his business.',
        doc: '11:12, 18 March 2022',
        managersID: ['ce5n5ww2-33un-zexb-lk15-4sph28q5u73e'],
        storesID: [],
        statusHistory: [
          {
            reason: 'Not a fraud',
            date: '12:02, 15 April, 2022',
            changedBy: 'Dr. Strange',
            status: 'deactivated'
          }
        ]
      },
      {
        merchantName: 'Flowers Shop',
        merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_3',
        contactPhone: '+380639479198',
        categoryCode: '5412 - Grocery Stores, Supermarkets',
        taxNumber: '5469264574265964',
        bankID: '63275469264574265964',
        kernelProfile: 'Profile 2',
        managerEmail: 'n.hurley@flowers-shop.com',
        status: 'active',
        statusText: '',
        doc: '11:12, 18 March 2022',
        managersID: [],
        storesID: ['0441lh61-9lxl-l01i-80lr-059bl4il3478', '0441lh61-9lxl-l01i-80lr-059bl4il3478_1'],
        statusHistory: [
          {
            reason: 'Merchant created',
            date: '14:51, 15 April, 2022',
            changedBy: 'William Ortiz',
            status: 'active'
          },
          {
            reason: 'Fraud suspicion',
            date: '16:38, 14 April, 2022',
            changedBy: 'William Ortiz',
            status: 'disabled'
          },
          {
            reason: 'Not a fraud',
            date: '18:05, 22 March, 2022',
            changedBy: 'William Ortiz',
            status: 'active'
          }
        ]
      },
    ],
    managers: [
      {
        managerName: 'Darren Howard',
        managerEmail: 'd.howard@42shop.com',
        managerID: 'qasw3ne3-xkuk-p6rj-lk15-2ma4uptutfw7',
        managerStatus: 'active',
        managerDoc: '19:12, 22 March 2020',
        managerIP: '187.162.211.71',
        lastVisit: '19:12, 22 March 2022',
        activityLog: [
          {
            action: 'Activate merchant Cafe 42.',
            doc: '19:12, 22 March 2022'
          },
          {
            action: 'Register store 42Shop-12.',
            doc: '18:12, 24 March 2022'
          },
          {
            action: 'Register store 42Shop-165.',
            doc: '17:12, 25 March 2022'
          },
          {
            action: 'Register merchant Fast taxi.',
            doc: '16:12, 26 March 2022'
          },
          {
            action: 'Register store 42Shop-112.',
            doc: '15:12, 27 March 2022'
          },
          {
            action: 'Delete merchant Choco.',
            doc: '14:12, 28 March 2022'
          },
          {
            action: 'Disable store Umka icecream-12.',
            doc: '13:12, 29 March 2022'
          },
          {
            action: 'Register merchant Olympic stadium.',
            doc: '12:12, 30 March 2022'
          }

        ],
        statusHistory: [
          {
            date: '12:38, 22 March 2022',
            status: 'active',
            changedBy: 'William Ortiz',
            reason: 'Manager is on vacation'
          },
          {
            date: '12:38, 22 March 2022',
            status: 'active',
            changedBy: 'William Ortiz',
            reason: 'Manager created'
          },
          {
            date: '12:38, 22 March 2022',
            status: 'disabled',
            changedBy: 'William Ortiz',
            reason: 'Manager is on vacation'
          },
          {
            date: '12:38, 22 March 2022',
            status: 'active',
            changedBy: 'William Ortiz',
            reason: 'Manager is on vacation'
          },
          {
            date: '12:38, 22 March 2022',
            status: 'deactivated',
            changedBy: 'William Ortiz',
            reason: 'Manager returned from vacation'
          },
          {
            date: '12:38, 22 March 2022',
            status: 'disabled',
            changedBy: 'William Ortiz',
            reason: 'Manager created'
          }
        ],
        managingMerchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_1'
      },
      {
        managerName: 'Glenn Ryan',
        managerEmail: 'g.ryan@umka.com',
        managerID: 'e3xyucn5-0a02-9khy-xhq4-kpqwfbmjek9y',
        managerStatus: 'disabled',
        managerDoc: '19:12, 22 March 2020',
        managerIP: '99.162.11.171',
        lastVisit: '14:11, 22 March 2023',
        activityLog: [],
        statusHistory: [],
        managingMerchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_1'
      },
      {
        managerName: 'Lia Holland',
        managerEmail: 'l.holland@fast-taxi.com',
        managerID: 'ce5n5ww2-33un-zexb-lk15-4sph28q5u73e',
        managerStatus: 'deactivated',
        managerDoc: '19:12, 22 March 2020',
        managerIP: '199.241.121.49',
        lastVisit: '22:08, 14 April 2022',
        activityLog: [],
        statusHistory: [],
        managingMerchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_2'
      }
    ],
    admins: [
      {
        adminName: 'Grady Joseph',
        adminEmail: 'g.joseph@bank.com',
        adminID: 'l801ly41-7ll1-125y-s0sl-pjlyx0178l3l',
        adminStatus: 'active',
        adminStatusText: '',
        adminDoc: '19:12, 20 March 2022',
        activityLog: [
          {
            uid: 'ebhvoibevnwduih2eiub',
            doc: '19:12, 22 March 2022',
            action: 'Register merchant: Fast taxi.'
          },
          {
            uid: 'ebhvoib123evnwduih2ei',
            doc: '18:35, 22 March 2022',
            action: 'Register store: 42Shop-11.'
          },
          {
            uid: 'adfnvapaspdnsd1oinsvl',
            doc: '17:25, 22 March 2022',
            action: 'Register store: 42Shop-67.'
          },
          {
            uid: 'fpbijnejkbno2inovinso',
            doc: '14:52, 22 March 2022',
            action: 'Register store: 42Shop-9.'
          },
          {
            uid: 'dfbkv8iwunv92klsndvb',
            doc: '18:08, 22 March 2022',
            action: 'Register store: Flowers Shop-JY.'
          },
          {
            uid: 'sfvbsdkjlnsdjbskjdnfkjs',
            doc: '17:25, 22 March 2022',
            action: 'Register merchant: Olympic stadium.'
          },
        ],
        statusHistory: [
          {
            date: '12:38, 22 March 2022',
            status: 'active',
            changedBy: 'John Williams',
            reason: 'Admin created'
          },
          {
            date: '12:38, 22 March 2022',
            status: 'disabled',
            changedBy: 'John Williams',
            reason: 'Admin disabled'
          }
        ],
        adminIP: '',
        adminLastVisit: ''
      },
      {
        adminName: 'Jack Carson',
        adminEmail: 'j.carson@bank.com',
        adminID: '7lu80l81-5liv-l35l-60ji-301b10lylld1',
        adminStatus: 'disabled',
        adminStatusText: 'Status changed at 18:35, 14 April, 2022 by John Williams. Admin on vacation.',
        adminDoc: '18:35, 21 March 2022',
        activityLog: [
          {
            uid: 'ebhvoibevnwduih2eiub',
            doc: '19:12, 22 March 2022',
            action: 'Register merchant: Fast taxi.'
          },
          {
            uid: 'ebhvoib123evnwduih2ei',
            doc: '18:35, 22 March 2022',
            action: 'Register store: 42Shop-11.'
          },
          {
            uid: 'adfnvapaspdnsd1oinsvl',
            doc: '17:25, 22 March 2022',
            action: 'Register store: 42Shop-67.'
          },
          {
            uid: 'fpbijnejkbno2inovinso',
            doc: '14:52, 22 March 2022',
            action: 'Register store: 42Shop-9.'
          },
          {
            uid: 'dfbkv8iwunv92klsndvb',
            doc: '18:08, 22 March 2022',
            action: 'Register store: Flowers Shop-JY.'
          },
          {
            uid: 'sfvbsdkjlnsdjbskjdnfkjs',
            doc: '17:25, 22 March 2022',
            action: 'Register merchant: Olympic stadium.'
          },
        ],
        statusHistory: [
          {
            date: '12:38, 22 March 2022',
            status: 'active',
            changedBy: 'Briana Henson',
            reason: 'Admin created'
          }
        ],
        adminIP: '',
        adminLastVisit: ''
      },
      {
        adminName: 'Amada Grant',
        adminEmail: 'a.grant@bank.com',
        adminID: '00t3n72l-ld6e-8l4t-1llg-4l0u0ll0k117',
        adminStatus: 'active',
        adminStatusText: '',
        adminDoc: '14:44, 22 March 2022',
        activityLog: [],
        statusHistory: [
          {
            date: '12:38, 22 March 2022',
            status: 'active',
            changedBy: 'Briana Henson',
            reason: 'Admin created'
          }
        ],
        adminIP: '',
        adminLastVisit: ''
      },
      {
        adminName: 'John Williams',
        adminEmail: 'j.williams@bank.com',
        adminID: '70zlnk9c-ll01-lla0-89l1-c30mt00ll474',
        adminStatus: 'deactivated',
        adminStatusText: 'Status changed at 11:08, 19 April, 2022 by John Williams. No longer works in the company.',
        adminDoc: '12:38, 23 March 2022',
        activityLog: [],
        statusHistory: [
          {
            date: '12:38, 22 March 2022',
            status: 'active',
            changedBy: 'Briana Henson',
            reason: 'Admin created',
          },
          {
            date: '12:38, 22 March 2022',
            status: 'deactivated',
            changedBy: 'Briana Henson',
            reason: 'Admin deactivated'
          }
        ],
        adminIP: '',
        adminLastVisit: ''
      }
    ],
    stores: [
      {
        storeName: '42Shop-22',
        storeProfileName: '42Shop-storeprofile',
        storeID: '0441lh61-9lxl-l01i-80lr-059bl4il3478',
        storeMerchantName: {
          merchantName: 'Flowers Shop',
          merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_3'
        },
        storePhone: '+380978768696',
        storeEmail: 'info22@42shop.com',
        storeZipcode: '02098',
        storeCountry: 'Ukraine',
        storeCity: 'Kyiv',
        storeState: 'Kyiv reqion',
        storeAddress: '1V Pavla Tychyny Avenue',
        storeStatus: 'active',
        storeStatusText: '',
        storeDoc: '19:12, 22 March 2022',
        transactionHistory: [
          {
            uid: '',
            number: '1003772499',
            sellerName: 'Lindsey Kim',
            deviceModel: 'Sony G3112',
            status: 'approved',
            amount: '44.00',
            datetime: '16:14, 23 March 2022',
            transactionDetail: {
              aid: 'A0000000031010',
              date: '16:14, 23 March 2022',
              cardNumber: '**** 9601',
              paymentSystem: 'Visa',
              refund: false,
              amount: '44.00',
              tips: '4.40',
              inputMethod: 'Contactless',
              responseCode: '116',
              terminalID: '347801l0-zlla-e41l-59u9-4l1s31i0leml',
              merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i'
            }
          },
          {
            uid: '',
            number: '1003772498',
            sellerName: 'Lindsey Kim',
            deviceModel: 'Sony G3111',
            status: 'aborted',
            amount: '34.00',
            datetime: '15:25, 23 March 2022',
            transactionDetail: {
              aid: 'A009999031010',
              date: '15:25, 23 March 2022',
              cardNumber: '**** 1240',
              paymentSystem: 'Visa',
              refund: false,
              amount: '34.00',
              tips: '2.40',
              inputMethod: 'Contactless',
              responseCode: '116',
              terminalID: '347801l0-zlla-e41l-59u9-4l1s31i0leml',
              merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i',
            }
          },
          {
            uid: '',
            number: '1003772497',
            sellerName: 'Lindsey Kim',
            deviceModel: 'Sony G3113',
            status: 'declined',
            amount: '105.00',
            datetime: '14:36, 23 March 2022',
            transactionDetail: {
              aid: 'A0099961202020',
              date: '14:36, 23 March 2022',
              cardNumber: '**** 1240',
              paymentSystem: 'Visa',
              refund: false,
              amount: '105.00',
              tips: '21.15',
              inputMethod: 'Contactless',
              responseCode: '116',
              terminalID: '347801l0-zlla-e41l-59u9-4l1s31i0leml',
              merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i'
            }
          },
          {
            uid: '',
            number: '1003772496',
            sellerName: 'Lindsey Kim',
            deviceModel: 'Sony G3112',
            status: 'approved',
            amount: '44.25',
            datetime: '13:47, 23 March 2022',
            transactionDetail: {
              aid: 'A009996194994941',
              date: '13:47, 23 March 2022',
              cardNumber: '**** 9901',
              paymentSystem: 'MasterCard',
              refund: true,
              amount: '44.25',
              tips: '0',
              inputMethod: 'Contactless',
              responseCode: '126',
              terminalID: '347801l0-zlla-e41l-59u9-4l1s31i0leml',
              merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i'
            }
          },
          {
            uid: '',
            number: '1003772495',
            sellerName: 'Lindsey Kim',
            deviceModel: 'Sony G3112',
            status: 'aborted',
            amount: '12.00',
            datetime: '12:58, 23 March 2022',
            transactionDetail: {
              aid: 'A00999619452471',
              date: '12:58, 23 March 2022',
              cardNumber: '**** 1901',
              paymentSystem: 'MasterCard',
              refund: true,
              amount: '12.00',
              tips: '2.00',
              inputMethod: 'Contactless',
              responseCode: '103',
              terminalID: '347801l0-zlla-e41l-59u9-4l1s31i0leml',
              merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i'
            }
          },
          {
            uid: '',
            number: '1003772494',
            sellerName: 'Lindsey Kim',
            deviceModel: 'Sony G3112',
            status: 'declined',
            amount: '88.88',
            datetime: '11:03, 23 March 2022',
            transactionDetail: {
              aid: 'A009996190018765',
              date: '11:03, 23 March 2022',
              cardNumber: '**** 1901',
              paymentSystem: 'Visa',
              refund: true,
              amount: '88.88',
              tips: '11.12',
              inputMethod: 'Cash',
              responseCode: '103',
              terminalID: '347801l0-zlla-e41l-59u9-4l1s31i0leml',
              merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i'
            }
          }
        ],
        terminals: [
          {
            terminalID: '36628528',
            applicationID: 'p0xll6n1-6y05-lz6l-l1ge-34781l60l10l',
            deviceModel: 'Google Pixel 3a',
            status: 'active',
            statusText: '',
            doc: '19:12, 22 March 2023'
          },
          {
            terminalID: '36628531',
            applicationID: '6zv10i11-msl0-3478-zl1l-0l2n292l0lhl',
            deviceModel: 'Xiaomi MI 8',
            status: 'deactivated',
            doc: '19:12, 12 March 2022'
          }
        ]
      },
      {
        storeName: '42Shop-23',
        storeProfileName: '42Shop-storeprofile-next',
        storeID: '0441lh61-9lxl-l01i-80lr-059bl4il3478_1',
        storeMerchantName: {
          merchantName: 'Flowers Shop',
          merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_3'
        },
        storePhone: '+380978768696',
        storeEmail: 'info22@42shop.com',
        storeZipcode: '02098',
        storeCountry: 'Ukraine',
        storeCity: 'Lviv',
        storeState: 'Lviv reqion',
        storeAddress: 'Viacheslava Chornovola Avenue, 47A',
        storeStatus: 'active',
        storeStatusText: '',
        storeDoc: '19:12, 22 March 2023',
      },
      {
        storeName: 'SteakHouse-33',
        storeProfileName: 'Steak House - storeprofile',
        storeID: '0441lh61-9lxl-l01i-80lr-059bl4il3478_2',
        storeMerchantName: {
          merchantName: 'Steak House',
          merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_1'
        },
        storePhone: '+380978768696',
        storeEmail: 'info22@42shop.com',
        storeZipcode: '02098',
        storeCountry: 'Ukraine',
        storeCity: 'Kherson',
        storeState: 'Kherson reqion',
        storeAddress: 'Perekopska St, 20',
        storeStatus: 'disabled',
        storeStatusText: '11:32, 12 December, 2022 by Jack Carson. The store was closed.',
        storeDoc: '19:12, 26 March 2022',
      },
      {
        storeName: 'SteakHouse-34',
        storeProfileName: 'Steak House - storeprofile',
        storeID: '0441lh61-9lxl-l01i-80lr-059bl4il3478_4',
        storeMerchantName: {
          merchantName: 'Steak House',
          merchantID: 's0lcll80-150l-4lrl-ld11-924d04lxt20i_1'
        },
        storePhone: '+380978768696',
        storeEmail: 'info22@42shop.com',
        storeZipcode: '02098',
        storeCountry: 'Ukraine',
        storeCity: 'Kherson',
        storeState: 'Kherson reqion',
        storeAddress: 'Perekopska St, 20',
        storeStatus: 'deactivated',
        storeStatusText: '11:32, 12 December, 2022 by Jack Carson. The store was closed.',
        storeDoc: '19:12, 26 March 2022',
      }
    ],

    kernels: [
      {
        kernelProfileName: 'Kernel Profile name 1',
        kernelCounterCode: 'Ukraine (804)',
        kernelCategoryCode: '5462 - Bakeries',
        kernelCurrencyCode: '980 - UAH',
        interfaceProfiles: {
          interfaceProfileSVID: 'A00000004(MasterCard)',
          transactionTypeProfile: {
            transactionTypeProfile: 'CashWithdrawal',
            accountTypes: ['Default', 'Saving', 'Credit'],
            additionalCurrencyCodes: ['UAH (980)', 'GBP (826)'],
            additionalServices: 'Cashback',
            applicationSelectionIndicator: 'Full',
            CVMAtOrAboveLimits: ['AnyOnDeviceCVM', 'MOIPIN', 'SignatureAfterTransotion'],
            CVMBelowLimits: 'NoCVM',
            CVMLimit: '150',
            floorLimit: 'Online',
            installmentThreshold: '150',
            ODARequirements: 'Online',
            maximumLimitODCVM: '150',
            kernelOptions: 'EMVMODE',
            maximumLimit: 'Online',
            onlineRandomMaximumTarget: '2',
            onlineRandomTarget: '150',
            onlineRandomThreshold: '150',
            terminalRiskManagementData: '4',
            transactionCategoryCode: 'A',
            zeroAmountAction: 'Decline',
          },

        }
      }
    ]
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    getAppReadyState: state => state.isAppReady,
    getCurrentUser: state => state.currentUser,
    displayLogoutModal: state => state.displayLogoutModal,
    displayAddMerchantModal: state => state.displayAddMerchantModal,
    displayEnableMerchantModal: state => state.displayEnableMerchantModal,
    displayDisableMerchantModal: state => state.displayDisableMerchantModal,
    displayMerchantModalDeactivate: state => state.displayMerchantModalDeactivate,
    displayManagerModalAdd: state => state.displayManagerModalAdd,
    displayAdminModalAdd: state => state.displayAdminModalAdd,
    displayAdminModalEdit: state => state.displayAdminModalEdit,
    displayManagerModalEdit: state => state.displayManagerModalEdit,
    displayStoreModalAdd: state => state.displayStoreModalAdd,
    displayStoreModalEdit: state => state.displayStoreModalEdit,
    displayCloseTimeModal: state => state.displayCloseTimeModal,
    displayStoreModalEDD: state => state.displayStoreModalEDD,
    displayManagerModalEDD: state => state.displayManagerModalEDD,
    displayAdminModalEDD: state => state.displayAdminModalEDD,
    displayMerchantModalEDD: state => state.displayMerchantModalEDD,
    getMerchants: state => state.merchants,
    getMerchant: state => id => state.merchants.find(item => item.merchantID === id),
    getTerminal: state => function(id){
      const terminal = {};
      state.stores.filter(function(item){
        if(item.terminals === undefined) return;
        item.terminals.find(el => {
          if(el.terminalID === id){
            Object.assign(terminal, el);
          }
        })
      })
      return terminal;
    },
    getAllManagers: state => state.managers,
    getAllAdmins: state => state.admins,
    getManager: state => id => state.managers.find(item => item.managerID === id),
    getAllStores: state => state.stores,
    getStore: state => id => state.stores.find(item => item.storeID === id),
    displayResetPassManagerModal: state => state.displayResetPassManagerModal,
    displayResetPassAdminModal: state => state.displayResetPassAdminModal,
    getAdmin: state => id => state.admins.find(item => item.adminID === id),
    displayTerminalModalAdd: state => state.displayTerminalModalAdd
  },
  mutations: {
    changeLoginState: (state, payload) => {
      state.currentUser = structuredClone(payload);
      state.isLoggedIn = !state.isLoggedIn

    },
    changeAppState: state => state.appReady = true,
    displayLogoutModal: state => state.displayLogoutModal = !state.displayLogoutModal,
    displayAddMerchantModal: state => state.displayAddMerchantModal = !state.displayAddMerchantModal,
    // displayEnableMerchantModal: state => state.displayEnableMerchantModal = !state.displayEnableMerchantModal,
    // displayDisableMerchantModal: state => state.displayDisableMerchantModal = !state.displayDisableMerchantModal,
    displayModalManagerAdd: state => state.displayManagerModalAdd = !state.displayManagerModalAdd,
    displayModalAdminAdd: state => state.displayAdminModalAdd = !state.displayAdminModalAdd,
    displayModalAdminEdit: state => state.displayAdminModalEdit = !state.displayAdminModalEdit,
    displayModalManagerEdit: state => state.displayManagerModalEdit = !state.displayManagerModalEdit,
    displayModalStoreAdd: state => state.displayStoreModalAdd = !state.displayStoreModalAdd,
    displayTerminalModalAdd: state => state.displayTerminalModalAdd = !state.displayTerminalModalAdd,
    displayModalStoreEdit: state => state.displayStoreModalEdit = !state.displayStoreModalEdit,
    displayCloseTimeModal: state => state.displayCloseTimeModal = !state.displayCloseTimeModal,
    displayStoreModalEDD: state => state.displayStoreModalEDD = !state.displayStoreModalEDD,
    displayManagerModalEDD: state => state.displayManagerModalEDD = !state.displayManagerModalEDD,
    displayAdminModalEDD: state => state.displayAdminModalEDD = !state.displayAdminModalEDD,
    displayMerchantModalEDD: state => state.displayMerchantModalEDD = !state.displayMerchantModalEDD,
    // displayMerchantModalDeactivate: state => state.displayMerchantModalDeactivate = !state.displayMerchantModalDeactivate,
    updateMerchantData: (state, payload) => state.merchants.map(item => {
      if(item.merchantID === payload.merchantID){
        return Object.assign(item, payload)
      }
      return item
    }),
    addManager: (state, payload) => {
      state.merchants.filter(item => {
        if(payload.id === item.merchantID){
          return item.managersID.push(payload.manager.managerID)
        }
        return item
      })
      state.managers.push(payload.manager);

    },
    editManager: (state, payload) => {
      console.log(payload)
      state.merchants.map( item => {
        if(item.merchantID === payload.oldMerchant[0].merchantID){
          let index = item.managersID.indexOf(payload.manager.managerID);
          item.managersID.splice(index, 1);
        }
        if(item.merchantID === payload.id){
          item.managersID.push(payload.manager.managerID);
        }
      })
      state.managers.map(item => {
        if(item.managerID === payload.manager.managerID){
          return Object.assign(item, payload.manager)
        }
        return item
      })
    },
    addStore: (state, payload) => {
      state.merchants.filter(item => {
        if(payload.id === item.merchantID){
          return item.storesID.push(payload.store.storeID)
        }
        return item
      })
      state.stores.push(payload.store);
    },
    updateStore: (state, payload) => {
      state.stores.map(item => {
        if(item.storeID === payload.id) {
          state.merchants.filter(itemM => {
            if(itemM.merchantID === payload.store.storeMerchantName.merchantID && !itemM.storesID.includes(payload.id)){
              itemM.storesID.push(payload.id);
            }
            if(itemM.merchantID !== payload.store.storeMerchantName.merchantID && itemM.storesID.includes(payload.id)){
              const index = itemM.storesID.indexOf(payload.store.storeID);
              itemM.storesID.splice(index, 1);
            }
          });
          Object.assign(item, payload.store)
        }
        return item;
      })


    },
    addMerchant: (state, payload) => {
      state.merchants.push(payload);
    },
    onSignIn: (state, payload) => {

    },
    displayResetPassManagerModal: state => state.displayResetPassManagerModal = !state.displayResetPassManagerModal,
    displayResetPassAdminModal: state => state.displayResetPassAdminModal = !state.displayResetPassAdminModal,
    // getProfile: (state, payload) => state.currentUser,
    addAdmin: (state, payload) => state.admins.push(payload),
    editAdmin: (state, payload) => {
      state.admins.filter(item => {
        if(item.adminID === payload.adminID){
          return Object.assign(item, payload)
        }
      })
    },
    addTerminal: (state, payload) => {
      state.stores.filter(item => {
        if(item.storeID === payload.id){
          if(item.hasOwnProperty('terminals')){
            item.terminals.push(payload.terminal)
          } else {
            item.terminals = [payload.terminal]
          }
        }
      })
    },
    updateCloseTimeTerminal: (state, payload) => {
      for( let item of state.stores){
        console.log(item, payload)
      }
    }
  },
  actions: {
    loginAction: ( { commit }, payload ) => commit('changeLoginState', payload),
    changeAppState: ( { commit } ) => { commit('changeAppState') },
    changeDisplayLogoutModal: ( { commit } ) => { commit('displayLogoutModal') },
    changeDisplayResetPassManagerModal: ( { commit } ) => { commit('displayResetPassManagerModal') },
    changeDisplayResetPassAdminModal: ( { commit } ) => { commit('displayResetPassAdminModal') },
    changeDisplayAddMerchantModal: ( { commit } ) => { commit('displayAddMerchantModal') },
    changeDisplayEnableMerchantModal: ( { commit } ) => { commit('displayEnableMerchantModal') },
    changeDisplayDisableMerchantModal: ( { commit } ) => { commit('displayDisableMerchantModal') },
    changeDisplayMerchantModalDeactivate: ( { commit } ) => { commit('displayMerchantModalDeactivate') },
    changeDisplayManagerModalAdd: ( { commit } ) => { commit('displayModalManagerAdd') },
    changeDisplayAdminModalAdd: ( { commit } ) => { commit('displayModalAdminAdd') },
    changeDisplayTerminalModalAdd: ( { commit } ) => { commit('displayTerminalModalAdd') },
    changeDisplayAdminModalEdit: ( { commit } ) => { commit('displayModalAdminEdit') },
    changeDisplayManagerModalEdit: ( { commit } ) => { commit('displayModalManagerEdit') },
    changeDisplayStoreModalAdd: ( { commit } ) => { commit('displayModalStoreAdd') },
    changeDisplayStoreModalEDD: ( { commit } ) => { commit('displayStoreModalEDD') },
    changeDisplayManagerModalEDD: ( { commit } ) => { commit('displayManagerModalEDD') },
    changeDisplayAdminModalEDD: ( { commit } ) => { commit('displayAdminModalEDD') },
    changeDisplayStoreModalEdit: ( { commit } ) => { commit('displayModalStoreEdit') },
    changeDisplayCloseTimeModal: ( { commit } ) => { commit('displayCloseTimeModal') },
    changeDisplayMerchantModalEDD: ( { commit } ) => { commit('displayMerchantModalEDD') },
    addManagerAction: ( { commit }, payload) => commit('addManager', payload),
    editManagerAction: ( { commit }, payload) => commit('editManager', payload),
    addStoreAction: ( { commit }, payload) => {
      commit('addStore', payload);
    },
    editStoreAction: ( { commit }, payload) => {
      commit('updateStore', payload);
    },
    addMerchantAction: ( { commit }, payload) => commit('addMerchant', payload),
    updateMerchantDataAction :( { commit }, payload) => commit('updateMerchantData', payload),
    addAdminAction: ({commit}, payload) => commit('addAdmin', payload),
    editAdminAction: ({commit}, payload) => commit('editAdmin', payload),
    addTerminalToStore: ( { commit }, payload ) => commit('addTerminal', payload),
    getTerminalFromStore: ( { commit }, id ) => commit('getTerminal', id),
    updateCloseTimeTerminalAction: ( { commit }, payload) => commit('updateCloseTimeTerminal', payload)
  },
  modules: {
  }
})
